import { MyAccountService } from "./index"
import { capitalizeWords, siteNameToTenant } from "../../utils"

class MyAccountProfileService extends MyAccountService {
	async getProfileContracts(contractID = undefined) {
		const client = await this.getClient()

		const response = await client.getProfileContracts({
			tenant: siteNameToTenant(),
			contractID: contractID,
		})

		if (!response.data?.contracts?.length) {
			return []
		}

		response.data.contracts.map((contract) => {
			contract.property.streetAddress = capitalizeWords(contract.property.streetAddress)
			contract.property.streetAddress2 = capitalizeWords(contract.property.streetAddress2)
			contract.property.city = capitalizeWords(contract.property.city)
			return contract
		})

		return response.data.contracts
	}
}

export default new MyAccountProfileService()
