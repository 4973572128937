export default {
	loading: false,
	fetchError: false,
	list: [],
	current: null,
	isAddingProperty: false,

	isDownloadingContract: false,

	isFirstYearCustomerInWaitingPeriod() {
		return Boolean(this.current?.salesChannel === "DC" && this.current?.status === "L")
	},
}
