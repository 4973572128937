import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { isValidEmailAddress, isValidPhoneNumber } from "../../utils"

export const selectIsLoggedIn = (state) => state.user.isLoggedIn
export const selectProfile = (state) => state.user.profile
export const selectErrorLoadingProfile = (state) => state.user.profileError
export const selectUserFirstName = (state) => state.user.profile?.firstName
export const selectUserLastName = (state) => state.user.profile?.lastName
export const selectUserInfo = (state) => state.user.user
export const selectIsSavingProfile = (state) => state.user.isSavingProfile
export const selectIsSavingPassword = (state) => state.user.isSavingPassword

export const selectIsProfileComplete = createSelector(
	(state) => state.user.profile,
	(profile) =>
		Boolean(
			profile &&
				profile.firstName.trim() !== "" &&
				profile.lastName.trim() !== "" &&
				isValidPhoneNumber(profile.phoneNumber) &&
				isValidEmailAddress(profile.emailAddress)
		)
)

export const selectUserFullName = createSelector(
	(state) => state.user.profile,
	(profile) => (profile?.firstName && profile?.lastName ? `${profile.firstName} ${profile.lastName}`.trim() : null)
)

export const selectSuppressedPromos = createSelector(
	[(state) => state.user.profile?.contractCustomer, (state) => state.contract.current?.contractID],
	(contractCustomer, currentContractID) =>
		contractCustomer?.find((contractCustomer) => contractCustomer.contractID === currentContractID)?.suppressedPromos ?? []
)

export const useIsLoggedIn = () => useSelector(selectIsLoggedIn)
export const useProfile = () => useSelector(selectProfile)
export const useErrorLoadingProfile = () => useSelector(selectErrorLoadingProfile)
export const useUserFirstName = () => useSelector(selectUserFirstName)
export const useUserLastName = () => useSelector(selectUserLastName)
export const useUserFullName = () => useSelector(selectUserFullName)
export const useUserInfo = () => useSelector(selectUserInfo)
export const useIsSavingProfile = () => useSelector(selectIsSavingProfile)
export const useIsSavingPassword = () => useSelector(selectIsSavingPassword)
export const useIsProfileComplete = () => useSelector(selectIsProfileComplete)
export const useSuppressedPromos = () => useSelector(selectSuppressedPromos)
