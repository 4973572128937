import React from "react"
import { Button, useLocalizedText } from "@ftdr/blueprint-components-react"
import { presetButtonPropsAlertPrimary, presetButtonPropsAlertSecondary } from "../../utils"

const AlertAction = ({ alert, button, onCloseClick }) => {
	const localizedText = useLocalizedText()

	const handleButtonClick = (event) => {
		if (button.isCloseButton) {
			onCloseClick(event)
		} else {
			if (typeof alert.options.onButtonClick === "function") {
				alert.options.onButtonClick(event)
			}

			if (typeof button.onClick === "function") {
				button.onClick(event)
			}
		}
	}

	const presetButtonProps = button.isAlternate ? presetButtonPropsAlertSecondary : presetButtonPropsAlertPrimary

	return (
		<Button
			label={localizedText(button.text)}
			size="small"
			color={alert.options.type === "info" ? "interactive" : alert.options.type}
			href={button.link || undefined}
			target={button.link ? button.target : undefined}
			rel={button.link && button.target ? "noopener noreferrer" : undefined}
			onClick={handleButtonClick}
			className="capitalize"
			{...presetButtonProps()}
		/>
	)
}

export default AlertAction
