import { ActionsBuilder } from "../utils"
import namespace from "./namespace"
import ContractService from "../../services/contract"
import MyAccountPaymentService from "../../services/my-account/payment"
import MyAccountProfileService from "../../services/my-account/profile"
import PaymentService from "../../services/payment"
import { AlertActions } from "../alert/actions"
import { PaymentActions } from "../payment/actions"
import { setCustomerID } from "../../analytics/user.analytics"
import { getContractCustomerID, makeCancelable } from "../../utils"
import { RequestServiceActions } from "../requestService/actions"

const actionsBuilder = new ActionsBuilder(namespace)

export const fetchContractsStart = actionsBuilder.createAction("fetchContractsStart", (state) => {
	state[namespace].loading = true
})

export const fetchContractsEnd = actionsBuilder.createAction("fetchContractsEnd", (state, { contracts, currentContract }) => {
	if (contracts && contracts.length) {
		state[namespace].list = contracts
		state[namespace].current = currentContract
	}

	state[namespace].loading = false
})

export const fetchContractsError = actionsBuilder.createAction("fetchContractsError", (state) => {
	state[namespace].loading = false
	state[namespace].fetchError = true
})

export const addPropertiesStart = actionsBuilder.createAction("addPropertiesStart", (state) => {
	state[namespace].isAddingProperty = true
})

export const addPropertiesEnd = actionsBuilder.createAction("addPropertiesEnd", (state) => {
	state[namespace].isAddingProperty = false
})

export const setCurrentContract = actionsBuilder.createAction("setCurrentContract", (state, contractID) => {
	const contract = state[namespace].list.find((contract) => contract.contractID === contractID)

	state[namespace].current = contract
})

export const removeContract = actionsBuilder.createAction("removeContract", (state, contractID) => {
	state[namespace].list = state[namespace].list.filter((contract) => contract.contractID !== contractID)

	// Shouldn't happen, but technically could happen
	if (state[namespace].current && state[namespace].current.contractID === contractID) {
		state[namespace].current = state[namespace].list[0]
	}
})

export const addExpirationInfoStart = actionsBuilder.createAction("addExpirationInfoStart", (state, { loadingPromise, contractID }) => {
	const contracts = state[namespace].list.slice()

	contracts.forEach((contract) => {
		if (contract.contractID === contractID) {
			contract.expirationInfoLoadingPromise?.cancel()
			contract.expirationInfoLoadingPromise = loadingPromise
			contract.expirationInfoLoading = true
		}
	})

	state[namespace].list = contracts
})

export const addExpirationInfoEnd = actionsBuilder.createAction("addExpirationInfoEnd", (state, { expirationInfo, contractID }) => {
	const contracts = state[namespace].list.slice()

	contracts.forEach((contract) => {
		if (contract.contractID === contractID) {
			contract.expirationInfoLoadingPromise?.cancel()
			contract.expirationInfoLoadingPromise = null
			contract.expirationInfoLoading = false
			contract.expirationInfo = expirationInfo
		}
	})

	state[namespace].list = contracts
})

export const addPaymentInfoStart = actionsBuilder.createAction("addPaymentInfoStart", (state, { loadingPromise, contractID }) => {
	const contracts = state[namespace].list.slice()

	contracts.forEach((contract) => {
		if (contract.contractID === contractID) {
			contract.paymentInfoLoadingPromise?.cancel()
			contract.paymentInfoLoadingPromise = loadingPromise
			contract.paymentInfo = null

			contract.paymentInfoError = false
		}
	})

	state[namespace].list = contracts
})

export const addPaymentInfoEnd = actionsBuilder.createAction("addPaymentInfoEnd", (state, { paymentInfo, contractID }) => {
	const contracts = state[namespace].list.slice()

	contracts.forEach((contract) => {
		if (contract.contractID === contractID) {
			contract.paymentInfoLoadingPromise?.cancel()
			contract.paymentInfoLoadingPromise = null
			if (paymentInfo) {
				contract.paymentInfo = paymentInfo
			}

			contract.paymentInfoError = !paymentInfo
		}
	})

	state[namespace].list = contracts
})

export const getPastDueAmountsStart = actionsBuilder.createAction("getPastDueAmountsStart", (state, { loadingPromise, contractID }) => {
	const contracts = state[namespace].list.slice()

	contracts.forEach((contract) => {
		if (contract.contractID === contractID) {
			contract.pastDueAmountsLoadingPromise?.cancel()
			contract.pastDueAmountsLoadingPromise = loadingPromise
			contract.pastDueAmountsLoading = true
			contract.pastDueAmounts = null

			contract.pastDueError = false
		}
	})

	state[namespace].list = contracts
})

export const getPastDueAmountsEnd = actionsBuilder.createAction("getPastDueAmountsEnd", (state, { pastDueAmounts, contractID }) => {
	const contracts = state[namespace].list.slice()

	contracts.forEach((contract) => {
		if (contract.contractID === contractID) {
			contract.pastDueAmountsLoadingPromise?.cancel()
			contract.pastDueAmountsLoadingPromise = null
			if (pastDueAmounts) {
				contract.pastDueAmounts = pastDueAmounts
			}

			contract.pastDueError = !pastDueAmounts

			contract.pastDueAmountsLoading = false
		}
	})

	state[namespace].list = contracts
})

export const addUpgradeInfoStart = actionsBuilder.createAction("addUpgradeInfoStart", (state, { loadingPromise, contractID }) => {
	const contracts = state[namespace].list.slice()

	contracts.forEach((contract) => {
		if (contract.contractID === contractID) {
			contract.upgradeInfoLoadingPromise?.cancel()
			contract.upgradeInfoLoadingPromise = loadingPromise
		}
	})

	state[namespace].list = contracts
})

export const addUpgradeInfoEnd = actionsBuilder.createAction("addUpgradeInfoEnd", (state, { upgradeInfo, contractID }) => {
	const contracts = state[namespace].list.slice()

	contracts.forEach((contract) => {
		if (contract.contractID === contractID) {
			contract.upgradeInfoLoadingPromise?.cancel()
			contract.upgradeInfoLoadingPromise = null
			contract.upgradeInfo = upgradeInfo
		}
	})

	state[namespace].list = contracts
})

export const getExpirationInfo = (contract) => async (dispatch) => {
	try {
		if (contract?.status === "A" || contract?.status === "L") {
			const loadingPromise = makeCancelable(ContractService.getExpirationInfo(contract.contractID))
			dispatch(addExpirationInfoStart({ loadingPromise, contractID: contract.contractID }))
			const expirationInfo = await loadingPromise.promise
			dispatch(addExpirationInfoEnd({ expirationInfo, contractID: contract.contractID }))
		} else {
			dispatch(addExpirationInfoEnd({ expirationInfo: null, contractID: contract.contractID }))
		}
	} catch (error) {
		if (!error.canceled) {
			console.error(error)
			dispatch(addExpirationInfoEnd({ expirationInfo: null, contractID: contract.contractID }))
		}
	}
}

export const getPaymentInfo = (contract) => async (dispatch) => {
	try {
		const loadingPromise = makeCancelable(PaymentService.fetchPaymentInfo(contract.contractID))
		dispatch(addPaymentInfoStart({ loadingPromise, contractID: contract.contractID }))

		const paymentInfo = await loadingPromise.promise
		dispatch(addPaymentInfoEnd({ paymentInfo, contractID: contract.contractID }))
	} catch (error) {
		if (!error.canceled) {
			console.error(error)
			dispatch(addPaymentInfoEnd({ paymentInfo: null, contractID: contract.contractID }))
		}
	}
}

export const getPastDueAmounts = (contract) => async (dispatch) => {
	try {
		const loadingPromise = makeCancelable(MyAccountPaymentService.getPastDueAmounts(contract.contractID))
		dispatch(getPastDueAmountsStart({ loadingPromise, contractID: contract.contractID }))

		const pastDueAmounts = await loadingPromise.promise
		dispatch(getPastDueAmountsEnd({ pastDueAmounts, contractID: contract.contractID }))
	} catch (error) {
		if (!error.canceled) {
			console.error(error)
			dispatch(getPastDueAmountsEnd({ pastDueAmounts: null, contractID: contract.contractID }))
		}
	}
}

export const getUpgradeInfo = (contract) => async (dispatch) => {
	try {
		const loadingPromise = makeCancelable(ContractService.getUpgradeInfo(contract.contractID))
		dispatch(addUpgradeInfoStart({ loadingPromise, contractID: contract.contractID }))

		const upgradeInfo = await loadingPromise.promise
		dispatch(addUpgradeInfoEnd({ upgradeInfo, contractID: contract.contractID }))
	} catch (error) {
		if (!error.canceled) {
			console.error(error)
			dispatch(addUpgradeInfoEnd({ upgradeInfo: null, contractID: contract.contractID }))
		}
	}
}

export const setDownloadingContract = actionsBuilder.createAction("setDownloadingContract", (state, { loading }) => {
	state[namespace].isDownloadingContract = loading
})

export const ContractActions = {
	fetchContracts:
		(currentContractID = undefined) =>
		async (dispatch, getState) => {
			dispatch(fetchContractsStart())

			const state = getState()

			let contracts = []
			let currentContract = null

			if (state.user.profile.contractCustomer.length > 0) {
				try {
					contracts = await MyAccountProfileService.getProfileContracts()
				} catch (e) {
					console.log("Failed to fetch contracts")

					dispatch(fetchContractsError())
					return false
				}

				if (!currentContractID) {
					currentContractID = window.sessionStorage.getItem("RequestServiceSetContract")

					if (currentContractID) {
						window.sessionStorage.removeItem("RequestServiceSetContract")
					} else {
						currentContractID = window.sessionStorage.getItem("currentContractID")
					}
				}

				currentContract = contracts.find((contract) => contract.contractID === currentContractID) || contracts[0]

				// Set the current contract to be remembered
				window.sessionStorage.setItem("currentContractID", currentContract.contractID)
			}

			dispatch(fetchContractsEnd({ contracts, currentContract }))

			if (currentContract) {
				setCustomerID(getContractCustomerID(state.user.profile.contractCustomer, currentContract.contractID))

				await Promise.all([
					dispatch(getExpirationInfo(currentContract)),
					dispatch(getPaymentInfo(currentContract)),
					dispatch(getPastDueAmounts(currentContract)),
					dispatch(getUpgradeInfo(currentContract)),
				])
			}

			// Note: If we need to wait for all contract datas to be loaded, await this Promise.all()
			Promise.all(
				contracts.reduce((promises, contract) => {
					if (contract !== currentContract) {
						promises.push(dispatch(getExpirationInfo(contract)))
						promises.push(dispatch(getPastDueAmounts(contract)))
						promises.push(dispatch(getUpgradeInfo(contract)))
					}

					return promises
				}, [])
			)

			dispatch(PaymentActions.setPaymentInfoFromCurrentContract())
			dispatch(PaymentActions.setPastDueAmountsFromCurrentContract())
			dispatch(RequestServiceActions.getServiceability())
			return true
		},

	setCurrentContract: (contractID) => async (dispatch, getState) => {
		const state = getState()

		// Set the current contract to be remembered
		window.sessionStorage.setItem("currentContractID", contractID)

		if (state.user.isLoggedIn) {
			const newContract = state.contract.list.find((contract) => contract.contractID === contractID)
			if (newContract) {
				dispatch(setCurrentContract(contractID))
				setCustomerID(getContractCustomerID(state.user.profile.contractCustomer, contractID))
				dispatch(RequestServiceActions.getServiceability())

				if (newContract.paymentInfo) {
					dispatch(PaymentActions.setPaymentInfoFromCurrentContract())
				} else {
					dispatch(PaymentActions.fetchPaymentInfo())
				}

				if (newContract.pastDueAmounts) {
					dispatch(PaymentActions.setPastDueAmountsFromCurrentContract())
				} else {
					dispatch(PaymentActions.fetchPastDueAmounts())
				}
			}
		}
	},

	addProperties: (contracts) => async (dispatch) => {
		dispatch(addPropertiesStart())

		let response = null
		try {
			response = await ContractService.addProperties(contracts)
		} catch (e) {
			response = e.response
		}

		dispatch(addPropertiesEnd())

		return response
	},

	removeContract: (contractID) => async (dispatch, getState) => {
		const state = getState()
		const contract = state[namespace].list.find((contract) => contract.contractID === contractID)
		const id = `remove_contract_${contract.contractID}`

		// remove existing alert in case it exists
		dispatch(AlertActions.removeAlert(id))

		try {
			await ContractService.removeContract(contract.contractID)

			dispatch(removeContract(contract.contractID))
			dispatch(
				AlertActions.createAlert({
					type: "success",
					message: "ADDRESS_BAR.REMOVE_ADDRESS_SUCCESS",
					messageData: {
						streetAddress: contract.property.streetAddress,
					},
					closeButton: true,
				})
			)
		} catch (e) {
			const options = {
				type: "error",
				message: "ADDRESS_BAR.REMOVE_ADDRESS_ERROR",
				button: {
					text: "ADDRESS_BAR.REMOVE_ADDRESS_TRY_AGAIN",
					link: "#",
					onClick: (e) => {
						e.preventDefault()
						dispatch(ContractActions.removeContract(contractID))
					},
				},
				messageData: { streetAddress: contract.property.streetAddress },
			}

			dispatch(AlertActions.createAlert({ id, options }))
		}
	},

	getExpirationInfo: () => async (dispatch, getState) => {
		const state = getState()

		await dispatch(getExpirationInfo(state[namespace].current))
	},

	downloadContract: (contractID) => async (dispatch) => {
		dispatch(setDownloadingContract({ loading: true }))
		return ContractService.getContractDocument(contractID)
			.then((response) => {
				window.open(response.link, "_blank")
			})
			.finally(() => dispatch(setDownloadingContract({ loading: false })))
	},
}

export const actions = actionsBuilder.exportActions()
