import { useSelector } from "react-redux"
import { createSelector } from "reselect"

// Basic stuff before starting service request

export const selectIsServiceable = (state) => state.requestService.isServiceable
export const selectIsServiceableLoading = (state) => Boolean(state.requestService.isServiceableLoadingPromise)
export const selectSessionUUID = (state) => state.requestService.sessionUUID
export const selectSessionExp = (state) => state.requestService.sessionExp
export const selectRequestServiceContract = (state) => state.requestService.contract
export const selectIsRequestCurrentContract = createSelector(
	[(state) => state.user.isLoggedIn, (state) => state.contract.current, (state) => state.requestService.contract],
	(isLoggedIn, currentContract, requestServiceContract) =>
		Boolean(isLoggedIn && currentContract && requestServiceContract && currentContract.contractID === requestServiceContract.contractID)
)

export const useIsServiceable = () => useSelector(selectIsServiceable)
export const useIsServiceableLoading = () => useSelector(selectIsServiceableLoading)
export const useSessionUUID = () => useSelector(selectSessionUUID)
export const useSessionExp = () => useSelector(selectSessionExp)
export const useRequestServiceContract = () => useSelector(selectRequestServiceContract)
export const useIsRequestCurrentContract = () => useSelector(selectIsRequestCurrentContract)

// Picking items

export const selectIsLoadingServiceItems = (state) => state.requestService.serviceItemCategoriesLoading
export const selectErrorLoadingServiceItems = (state) => state.requestService.serviceItemCategoriesError
export const selectServiceItemCategories = (state) => state.requestService.serviceItemCategories
export const selectDwellingTypeCode = (state) => state.requestService.dwellingTypeCode
export const selectSelectedServiceItems = (state) => state.requestService.selectedServiceItems
export const selectPricing = (state) => state.requestService.pricing

export const useIsLoadingServiceItems = () => useSelector(selectIsLoadingServiceItems)
export const useErrorLoadingServiceItems = () => useSelector(selectErrorLoadingServiceItems)
export const useServiceItemCategories = () => useSelector(selectServiceItemCategories)
export const useDwellingTypeCode = () => useSelector(selectDwellingTypeCode)
export const useSelectedServiceItems = () => useSelector(selectSelectedServiceItems)
export const usePricing = () => useSelector(selectPricing)

// Item details

export const selectSkipItemsDetail = (state) => state.requestService.skipItemsDetail
export const selectServiceItemOptionsMap = (state) => state.requestService.serviceItemOptionsMap

export const useSkipItemsDetail = () => useSelector(selectSkipItemsDetail)
export const useServiceItemOptionsMap = () => useSelector(selectServiceItemOptionsMap)

// Contractor

export const selectDefaultContractor = (state) => state.requestService.contractorOptions?.[0]
export const selectScheduleOptions = (state) => state.requestService.scheduleOptions

export const useDefaultContractor = () => useSelector(selectDefaultContractor)
export const useScheduleOptions = () => useSelector(selectScheduleOptions)

// Contact info, payment

export const selectSelectedContractor = (state) => state.requestService.contractorOption
export const selectSelectedScheduleOption = (state) => state.requestService.scheduleOption
export const selectDefaultContactInfo = (state) => state.requestService.defaultContactInfo

export const useSelectedContractor = () => useSelector(selectSelectedContractor)
export const useSelectedScheduleOption = () => useSelector(selectSelectedScheduleOption)
export const useDefaultContactInfo = () => useSelector(selectDefaultContactInfo)

// Confirmation

export const selectConfirmationDetails = (state) => state.requestService.confirmationDetails

export const useConfirmationDetails = () => useSelector(selectConfirmationDetails)
