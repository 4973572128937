import { Notification } from "@ftdr/blueprint-components-react"
import React from "react"

const ErrorPage = ({
	// Children will be the contents of the error message. Will default to a message if no children provided
	children,
}) => {
	return (
		<div className="container mx-auto mt-7">
			<Notification variant="floating" status="error" showStatusLabel={false} matchColor={true} showCloseButton={false} size="large">
				{children || "There was an error loading the page."}
			</Notification>
		</div>
	)
}

export default ErrorPage
