import classNames from "classnames"
import React from "react"

import Header from "./Header/Header"
import PageContent from "./PageContent"
import Footer from "./Footer/Footer"

import PageHeader from "./PageHeader"

const Page = ({
	// Title to go into the page header
	title,
	// Subtitle to go into the page header
	subTitle,
	// Whether to show the address selector in the page header
	useAddressSelector,
	// Bbreadcrumbs in the page header, omit for no breadcrumbs
	breadcrumbs,
	// Side content for page header, usually instead of showing address selector
	sideContent,
	// Additional classname for the page content section
	className,
	// Content to render in the page contenet section
	children,
	// Whether to hide the footer from the page
	removeFooter,
}) => {
	return (
		<div className="flex flex-col min-h-screen pt-12 md:pt-0">
			<Header />

			<PageHeader
				title={title}
				subTitle={subTitle}
				showAddressSelector={useAddressSelector}
				breadcrumbs={breadcrumbs}
				sideContent={sideContent}
			/>

			<PageContent className={classNames(className, "flex-1 p-4 md:pt-5")}>{children}</PageContent>

			{!removeFooter && <Footer />}
		</div>
	)
}

export default Page
