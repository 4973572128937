import { useLocalizedText } from "@ftdr/blueprint-components-react"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Redirect } from "react-router-dom"
import { isObject, setPageTitle } from "../../utils"
import LoginRedirect from "./LoginRedirect"

const PageRoute = ({ route }) => {
	let { meta, component: Component, ...rest } = route
	route = { ...rest }

	const localizedText = useLocalizedText()

	// TODO: This is bad practice, so we need a better way to do this for validating routes
	const state = useSelector((state) => state)

	useEffect(() => {
		if (meta?.title) {
			setPageTitle(localizedText(meta.title))
		}
	}, [])

	if (meta?.validateRoute) {
		let newRoute = null

		if (Array.isArray(meta.validateRoute)) {
			newRoute = meta.validateRoute.reduce((out, validateRoute) => (out ? out : validateRoute(state)), null)
		} else {
			newRoute = meta.validateRoute(state)
		}

		if (newRoute) {
			let rememberPreviousURL = false

			if (isObject(newRoute)) {
				rememberPreviousURL = newRoute.rememberPreviousURL
				newRoute = newRoute.route
			}

			const RedirectComponent = !rememberPreviousURL ? Redirect : LoginRedirect

			Component = () => {
				if (Array.isArray(route.path)) {
					return route.path.map((path) => <RedirectComponent key={path} from={path} to={newRoute} />)
				} else {
					return <RedirectComponent from={route.path} to={newRoute} />
				}
			}
		} else if (meta.componentOverride) {
			let componentOverride = null

			if (Array.isArray(meta.componentOverride)) {
				componentOverride = meta.componentOverride.reduce((out, componentOverride) => (out ? out : componentOverride(state)), null)
			} else {
				componentOverride = meta.componentOverride(state)
			}

			if (componentOverride) {
				Component = componentOverride
			}
		}
	}

	return <Component />
}

export default PageRoute
