import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Switch, Route, useLocation, useHistory } from "react-router-dom"
import querystring from "querystring"
import Loading from "../pages/Loading"
import UserPageRoute from "./components/UserPageRoute"
import userRoutes from "./routes/user"
import { AlertActions } from "../store/alert/actions"
import { ContractActions } from "../store/contract/actions"
import { OnPageChange } from "../analytics/common.analytics"
import { useCurrentContract, useCurrentCustomerID } from "../store/contract/selectors"

const PageRouter = () => {
	const location = useLocation()
	const history = useHistory()

	const dispatch = useDispatch()
	const currentContract = useCurrentContract()
	const currentCustomerID = useCurrentCustomerID()

	useEffect(() => {
		if (window.QSI?.API) {
			window.QSI.API.unload?.()
			window.QSI.API.load?.()
			window.QSI.API.run?.()
		}
	}, [location.pathname])

	useEffect(() => {
		OnPageChange(currentCustomerID)

		dispatch(AlertActions.removeAllAlerts())

		// Check for query params except on login since login redirects back with code from Fusion Auth
		if (!location.pathname.startsWith("/login") && location.search.length > 1) {
			const search = querystring.parse(location.search.substr(1))

			// Check if a contract was specified, then set that as current contract
			if (search.contractID) {
				dispatch(ContractActions.setCurrentContract(search.contractID))
			}

			// Check if proactive chat was specified
			if (search.chat) {
				window.sessionStorage.setItem("proactiveChat", "true")
			}

			// Check if doing request service auto lookup
			if (search.contractID && search.zipCode && location.pathname.startsWith("/request-service")) {
				window.sessionStorage.setItem("RequestServiceAutoLookup", JSON.stringify(search))
			}

			// Check if doing request service logged-in for a specific contract
			if (search.contractID && !search.zipCode && location.pathname.startsWith("/request-service")) {
				window.sessionStorage.setItem("RequestServiceSetContract", search.contractID)
			}

			// Check if doing request service preselect item
			if (search.itemID && location.pathname.startsWith("/request-service")) {
				window.sessionStorage.setItem("RequestServiceSelectItem", search.itemID)
			}

			if (search.otp && location.pathname.includes("/non-covered-charges-estimate")) {
				return
			}

			if (search.redirect && (location.pathname.includes("/complete-your-profile") || location.pathname.includes("/add-property"))) {
				return
			}

			// Remove the search from the URL
			history.replace(location.pathname + location.hash, location.state)
		}
	}, [location.pathname, currentContract])

	const getRouteKey = (route) => (Array.isArray(route.path) ? route.path[0] : route.path)
	const getRouteProps = ({ meta, component, ...route }) => route

	return (
		<React.Suspense fallback={<Loading />}>
			<Switch>
				{userRoutes.map((route) => (
					<Route key={getRouteKey(route)} {...getRouteProps(route)}>
						<UserPageRoute route={route} />
					</Route>
				))}
			</Switch>
		</React.Suspense>
	)
}

export default PageRouter
