import React, { useContext, useEffect, useMemo, useState } from "react"
import { FeatureTogglesContext } from "./Context"
import { getSiteName } from "../../utils"
import { FEATURE_TOGGLE_DEFAULT_VALUE } from "./constants"
import AuthService from "../../services/auth"
import jwtDecode from "jwt-decode"
import { useIsLoggedIn } from "../../store/user/selectors"

export const useFeatureToggleList = () => {
	const { getFeatureToggleList } = useContext(FeatureTogglesContext)

	return getFeatureToggleList()
}

export const useUserDataForFeatureToggle = (attributes) => {
	const isLoggedIn = useIsLoggedIn()
	const [userID, setUserID] = useState()

	useEffect(() => {
		let cancelled = false

		AuthService.getAccessToken().then(({ token, isGuestToken }) => {
			if (cancelled) return

			const claims = jwtDecode(token)
			setUserID(isGuestToken ? claims?.jti : claims?.sub)
		})

		return () => {
			cancelled = true
		}
	}, [])

	const tenant = getSiteName()
	const userRoleType = isLoggedIn ? "customer" : "guest"

	const userAttributes = useMemo(
		() => ({
			tenant,
			user_role_type: userRoleType,
			...attributes,
		}),
		[tenant, userRoleType, attributes]
	)

	return { userID, userAttributes }
}

export const useFeatureToggle = (featureToggleName, attributes) => {
	const { getFeatureToggle } = useContext(FeatureTogglesContext)
	const { userID, userAttributes } = useUserDataForFeatureToggle(attributes)
	const [ready, setReady] = useState(false)
	const [value, setValue] = useState(FEATURE_TOGGLE_DEFAULT_VALUE)

	useEffect(() => {
		if (userID) {
			setValue(getFeatureToggle(userID, featureToggleName, userAttributes))
		}
		setReady(Boolean(userID))
	}, [featureToggleName, userID, userAttributes])

	// Default to the function call if provider has no hooks
	// This will be a one-time check and does not react
	return [ready, value]
}

export const useFeatureToggleOn = (featureToggleName, attributes) => {
	const [ready, value] = useFeatureToggle(featureToggleName, attributes)
	return [ready, value === "on"]
}
