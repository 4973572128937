import { ActionsBuilder } from "../utils"
import namespace from "./namespace"
import ContactService from "../../services/contact"
import { makeCancelable } from "../../utils"
import contractNamespace from "../contract/namespace"

const actionsBuilder = new ActionsBuilder(namespace)

export const fetchContactInfoStart = actionsBuilder.createAction("fetchContactInfoStart", (state, loadingPromise) => {
	state[namespace].loading = true
	state[namespace].loadingPromise = loadingPromise

	state[namespace].fetchError = false
})

export const fetchContactInfoEnd = actionsBuilder.createAction("fetchContactInfoEnd", (state, contactInfo) => {
	if (contactInfo) {
		state[namespace].contactInfo = contactInfo
	}

	state[namespace].fetchError = !contactInfo

	state[namespace].loading = false
	state[namespace].loadingPromise = null
})

export const savingContactInfoStart = actionsBuilder.createAction("savingContactInfoStart", (state) => {
	state[namespace].saving = true
})

export const savingContactInfoEnd = actionsBuilder.createAction("savingContactInfoEnd", (state, contactInfo) => {
	if (contactInfo) {
		state[namespace].contactInfo = contactInfo
	}

	state[namespace].saving = false
})

export const fetchEfulfillmentPreferenceStart = actionsBuilder.createAction("fetchEfulfillmentPreferenceStart", (state, loadingPromise) => {
	state[namespace].preferenceLoadingPromise = loadingPromise

	state[namespace].preferenceFetchError = false
})

export const fetchEfulfillmentPreferenceEnd = actionsBuilder.createAction(
	"fetchEfulfillmentPreferenceEnd",
	(state, { eFulfillmentEnabled, eFulfillmentAccessible }) => {
		if (eFulfillmentEnabled !== undefined) {
			state[namespace].eFulfillmentEnabled = eFulfillmentEnabled
			// In case of undefined, set accessible to false
			state[namespace].eFulfillmentAccessible = Boolean(eFulfillmentAccessible)
		} else {
			state[namespace].preferenceFetchError = true
		}

		state[namespace].preferenceLoadingPromise = null
	}
)

export const savingEfulfillmentPreferenceStart = actionsBuilder.createAction("savingEfulfillmentPreferenceStart", (state) => {
	state[namespace].preferenceSaving = true
})

export const savingEfulfillmentPreferenceEnd = actionsBuilder.createAction(
	"savingEfulfillmentPreferenceEnd",
	(state, eFulfillmentEnabled) => {
		if (eFulfillmentEnabled !== undefined) {
			state[namespace].eFulfillmentEnabled = eFulfillmentEnabled
		}

		state[namespace].preferenceSaving = false
	}
)

export const ContactActions = {
	fetchContactInfo: () => async (dispatch, getState) => {
		const state = getState()
		const { loadingPromise } = state[namespace]
		const { current } = state[contractNamespace]

		if (loadingPromise) {
			loadingPromise.cancel()
		}

		if (!current) {
			return
		}

		const cancelablePromise = makeCancelable(ContactService.fetchContactInfo(current.contractID))
		dispatch(fetchContactInfoStart(cancelablePromise))

		try {
			const contactInfo = await cancelablePromise.promise

			dispatch(fetchContactInfoEnd(contactInfo))
		} catch (e) {
			if (!e.canceled) {
				dispatch(fetchContactInfoEnd())
			}
		}
	},
	saveContactInfo: (contactInfo) => async (dispatch, getState) => {
		const state = getState()
		const { saving } = state[namespace]
		const { current } = state[contractNamespace]

		if (saving) {
			throw new Error("Cannot save contact info before previous call has completed")
		}

		if (!current) {
			return
		}

		dispatch(savingContactInfoStart())

		contactInfo = Object.assign({}, state[namespace].contactInfo, contactInfo)

		try {
			await ContactService.saveContactInfo(current.contractID, contactInfo)

			dispatch(savingContactInfoEnd(contactInfo))
		} catch (e) {
			dispatch(savingContactInfoEnd())

			throw e
		}
	},
	fetchEfulfillmentPreference: () => async (dispatch, getState) => {
		const state = getState()
		const { preferenceLoadingPromise } = state[namespace]
		const contractID = state.contract.current.contractID

		if (preferenceLoadingPromise) {
			preferenceLoadingPromise.cancel()
		}

		const cancelablePromise = makeCancelable(ContactService.fetchEfulfillmentPreference(contractID))
		dispatch(fetchEfulfillmentPreferenceStart(cancelablePromise))

		try {
			const response = await cancelablePromise.promise

			dispatch(fetchEfulfillmentPreferenceEnd(response))
		} catch (e) {
			if (!e.canceled) {
				dispatch(fetchEfulfillmentPreferenceEnd({}))
			}
		}
	},
	saveEfulfillmentPreference: (eFulfillmentEnabled) => async (dispatch, getState) => {
		const state = getState()
		const { preferenceSaving } = state[namespace]
		const contractID = state.contract.current.contractID

		if (preferenceSaving) {
			throw new Error("Cannot save contact preferences before previous call has completed")
		}

		dispatch(savingEfulfillmentPreferenceStart())
		try {
			await ContactService.saveEfulfillmentPreference(contractID, eFulfillmentEnabled)

			dispatch(savingEfulfillmentPreferenceEnd(eFulfillmentEnabled))
		} catch (e) {
			dispatch(savingEfulfillmentPreferenceEnd())
			throw e
		}
	},
}

export const actions = actionsBuilder.exportActions()
