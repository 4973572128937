import React, { useMemo } from "react"
import { useDispatch } from "react-redux"
import {
	GlobalFooterGroupedLinksRowPreset,
	GlobalFooterMinimalFooterRowPreset,
	Separator,
	GlobalFooterAppStoresRowPreset,
	useLocalizedText,
	useNotifications,
} from "@ftdr/blueprint-components-react"
import { isSiteAHS } from "../../utils"
import ConfigService from "../../services/config"
import { ContractActions } from "../../store/contract/actions"
import { FooterID } from "../../services/homebase"
import { useFeatureToggleOn } from "../../contexts/FeatureToggles/utils"
import { FeatureToggleNames } from "../../contexts/FeatureToggles/constants"
import classNames from "classnames"
import { useCurrentContract, useIsDownloadingContract } from "../../store/contract/selectors"
import { iconRenderer, logoRenderer } from "./utils"
import { useFooterContent } from "../../store/zesty/selectors"

const Footer = () => {
	const localizedText = useLocalizedText()
	const { showNotification } = useNotifications()
	const [isAppIngressToggleReady, isAppIngressToggleEnabled] = useFeatureToggleOn(FeatureToggleNames.APP_INGRESS)

	const dispatch = useDispatch()
	const currentContract = useCurrentContract()
	const isDownloadingContract = useIsDownloadingContract()
	const footerContent = useFooterContent()

	const details = useMemo(() => {
		if (!footerContent) return null

		const isDownloadContractLink = (link) => {
			return link.id === FooterID.downloadContract
		}

		const handleDownloadLinkClick = (e) => {
			e.preventDefault()

			dispatch(ContractActions.downloadContract(currentContract.contractID)).catch(() => {
				showNotification({
					variant: "floating",
					status: "error",
					autoCloseDelay: 10000,
					showCloseButton: true,
					inline: true,
					children: <>{localizedText("DOWNLOAD_CONTRACT.ERROR")}</>,
				})
			})
		}

		const clickHandler = {
			[FooterID.downloadContract]: handleDownloadLinkClick,
		}

		const getUrlByType = (type, url, map) => {
			const domain = (map && map[type]) || ""
			return `${domain}${url}`
		}

		const mapLinks = (link) => ({
			children: link.menuText,
			className: "text-1",
			href: getUrlByType(link.type, link.menuLink, ConfigService.config.external),
			...(link.icon ? { startIcon: iconRenderer(link.icon), className: "text-interactive-700 text-1 font-bold" } : {}),
			disabled: isDownloadContractLink(link) && isDownloadingContract,
			onClick: clickHandler[link.id],
		})

		const filterLinks = (link) => {
			let include = true

			// download contract elements when user is logged out
			if (isDownloadContractLink(link)) {
				include = false
			}

			return include
		}

		const getAppStoreContent = () => {
			if (isSiteAHS()) {
				return {
					title: footerContent.ahsAppStore.title,
					subTitle: footerContent.ahsAppStore.subTitle,
					googlePlayStoreLink: footerContent.ahsAppStore.googleStoreLink,
					appleAppStoreLink: footerContent.ahsAppStore.appleStoreLink,
				}
			}
		}

		return {
			appStore: getAppStoreContent(),
			links: footerContent.section1.map((item, i) => ({
				title: item.menuText,
				orientation: "vertical",
				links: item.children.filter(filterLinks).map(mapLinks),
				linkType: item.linkType || "normal",
			})),
			minimalFooter: {
				legalText: footerContent.section2.legalText,
				social: footerContent.section2.social,
				links: footerContent.section2.links.map(mapLinks),
			},
		}
	}, [footerContent, currentContract, isDownloadingContract])

	const showAppStores = Boolean(isAppIngressToggleReady && isAppIngressToggleEnabled && details.appStore)

	return (
		details && (
			<div id="page-footer">
				<div className={classNames("container mx-auto", !showAppStores && "border-t-1 border-gray-300")}>
					{showAppStores && (
						<GlobalFooterAppStoresRowPreset
							title={details.appStore.title}
							subTitle={details.appStore.subTitle}
							googlePlayStoreLink={details.appStore.googlePlayStoreLink}
							appleAppStoreLink={details.appStore.appleAppStoreLink}
						/>
					)}
					<GlobalFooterGroupedLinksRowPreset alignment="between" accordionWhenMobileView={true} linkGroups={details.links} />
					<GlobalFooterMinimalFooterRowPreset
						logoRenderer={logoRenderer}
						legalText={details.minimalFooter.legalText}
						links={details.minimalFooter.links}
						facebook={details.minimalFooter.social.Facebook}
						twitter={details.minimalFooter.social.Twitter}
						youtube={details.minimalFooter.social.Youtube}
						pinterest={details.minimalFooter.social.Pinterest}
						linkedin={details.minimalFooter.social.Linkedin}
					/>
					<Separator orientation="horizontal" gap="sm" />
				</div>
			</div>
		)
	)
}

export default Footer
