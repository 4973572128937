import { useEffect, useState } from "react"
import Loading from "../pages/Loading"
import AuthService from "../services/auth"
import { SetMyAccountCookie, removeCurrentContractCookie, setAccessTokenToCookie, setCurrentContractIdToCookie } from "../utils"
import jwtDecode from "jwt-decode"
import { useCurrentContractID } from "../store/contract/selectors"

const DEBUG = false

const UserStateManager = ({ children }) => {
	const [loading, setLoading] = useState(true)
	const [user, setUser] = useState()

	const currentContractID = useCurrentContractID()

	useEffect(() => {
		AuthService.getClient()
			.getUser()
			.then(
				(user) => {
					setUser(user)
				},
				(error) => {
					console.error("[UserStateManager] error getting user", error)
				}
			)
			.then(() => {
				setLoading(false)
			})
	}, [])

	useEffect(() => {
		if (!user) return

		setAccessTokenToCookie()
		SetMyAccountCookie(true)

		const accessTokenExpirationDate = new Date(jwtDecode(user.access_token).exp * 1000)
		const refreshBeforeTokenExpIn = AuthService.getRefreshBeforeTokenExpIn()
		const timeLeftBeforeExpiration = accessTokenExpirationDate.getTime() - new Date().getTime()
		const timeLeftBeforeRefresh = timeLeftBeforeExpiration - refreshBeforeTokenExpIn * 1000

		if (DEBUG) {
			const hh = String(Math.floor(timeLeftBeforeRefresh / 1000 / 60 / 60))
			const mm = String(Math.floor(timeLeftBeforeRefresh / 1000 / 60) % 60).padStart(2, "0")
			const ss = String(Math.floor(timeLeftBeforeRefresh / 1000) % 60).padStart(2, "0")
			const ms = String(timeLeftBeforeRefresh % 1000).padStart(3, "0")

			console.log(`[UserStateManager] token will refresh in ${hh}:${mm}:${ss}.${ms}`)
		}

		setTimeout(() => {
			DEBUG && console.log("[UserStateManager] refreshing token...")

			AuthService.getClient()
				.renewToken()
				.then(
					(user) => {
						DEBUG && console.log("[UserStateManager] new user set after refresh token")
						setUser(user)
					},
					(error) => {
						console.error("[UserStateManager] error refreshing access token", error)
					}
				)
		}, timeLeftBeforeRefresh)
	}, [user])

	useEffect(() => {
		if (user && currentContractID) {
			setCurrentContractIdToCookie(currentContractID)
		} else {
			removeCurrentContractCookie()
		}
	}, [user, currentContractID])

	if (loading) {
		return <Loading />
	}

	return <>{children}</>
}

export default UserStateManager
