export const canShowUserAlert = (userAlert, userAlerts, isInPilotGroup, suppressedPromos, isServiceable, location) => {
	if (userAlert.pilotGroupType && !isInPilotGroup) return false
	if (userAlert.suppressible && suppressedPromos.includes(userAlert.promo)) return false
	if (userAlert.serviceItemID && !isServiceable) return false

	if (userAlert.showOnLogin) {
		return true
	}

	// If this is not shown on login and there are other alerts shown on login, then don't show this alert
	if (userAlerts.some((userAlert) => userAlert.showOnLogin)) return false

	if (userAlert.showOnAllPages) {
		return true
	}

	if (userAlert.showOnDashboard && location.pathname.startsWith("/dashboard")) {
		return true
	}

	if (
		userAlert.showOnRequestService &&
		location.pathname.startsWith("/request-service") &&
		!location.pathname.startsWith("/request-service-")
	) {
		return true
	}

	return false
}
