import React, { lazy, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { selectErrorLoadingProfile, selectIsLoggedIn, selectIsProfileComplete, selectProfile } from "../../store/user/selectors"
import { selectErrorLoadingContracts, selectIsLoadingContracts, selectNumContracts } from "../../store/contract/selectors"

const GenericTermsAndConditions = lazy(() => import("../../pages/GenericTermsAndConditions"))

const makeRedirectByLocationComponent = (transformLocationFn) => () => {
	const location = useLocation()
	useEffect(() => {
		window.location.replace(transformLocationFn(location))
	}, [location])
	return null
}

const routeCheckAuto = (state) => {
	if (!selectIsLoggedIn(state)) {
		return "/login"
	}
	if (!selectIsProfileComplete(state) && !selectErrorLoadingProfile(state)) {
		return "/complete-your-profile"
	}
	return "/dashboard"
}

const routeCheckComplete = (state) => {
	if (selectIsProfileComplete(state)) {
		return "/dashboard"
	}
}

const routeCheckLoggedIn = (state, ignoreIncompleteProfile = false) => {
	if (!selectIsLoggedIn(state)) {
		return { route: "/login", rememberPreviousURL: true }
	}
	if (!ignoreIncompleteProfile && !selectIsProfileComplete(state) && !selectErrorLoadingProfile(state)) {
		return { route: "/complete-your-profile", rememberPreviousURL: true }
	}
}

const routeCheckNotLoggedIn = (state) => {
	if (selectIsLoggedIn(state)) {
		if (!selectIsProfileComplete(state) && !selectErrorLoadingProfile(state)) {
			return { route: "/complete-your-profile", rememberPreviousURL: true }
		} else {
			return "/dashboard"
		}
	}
}

const routeCheckHasContracts = (state) => {
	if (
		selectIsLoggedIn(state) &&
		selectProfile(state) &&
		!selectIsLoadingContracts(state) &&
		!selectErrorLoadingContracts(state) &&
		selectNumContracts(state) === 0
	) {
		return { route: "/add-property", rememberPreviousURL: true }
	}
}

const routeOverrideComponentErrorProfile = (state) => {
	if (selectErrorLoadingProfile(state)) {
		return lazy(() => import("../../pages/Error"))
	}
}

const routeOverrideComponentErrorFetchContracts = (state) => {
	if (selectErrorLoadingContracts(state)) {
		return lazy(() => import("../../pages/Error"))
	}
}

const routes = [
	{
		path: "/",
		exact: true,
		meta: {
			validateRoute: routeCheckAuto,
		},
	},
	{
		path: "/complete-your-profile",
		component: lazy(() => import("../../pages/CompleteProfile")),
		meta: {
			title: "PAGE_TITLE.COMPLETE_YOUR_PROFILE",
			validateRoute: [(state) => routeCheckLoggedIn(state, true), routeCheckComplete],
			componentOverride: routeOverrideComponentErrorProfile,
		},
	},
	{
		path: "/login",
		component: lazy(() => import("../../pages/Login")),
		meta: {
			title: "PAGE_TITLE.LOGIN",
			validateRoute: routeCheckNotLoggedIn,
		},
	},
	{
		path: "/logout",
		component: lazy(() => import("../../pages/Logout")),
	},
	{
		path: "/dashboard",
		component: lazy(() => import("../../pages/Home")),
		meta: {
			title: "PAGE_TITLE.DASHBOARD",
			validateRoute: [routeCheckLoggedIn, routeCheckHasContracts],
			componentOverride: [routeOverrideComponentErrorProfile, routeOverrideComponentErrorFetchContracts],
		},
	},
	{
		path: "/payments/confirmation",
		component: lazy(() => import("../../pages/PaymentConfirmation")),
		meta: {
			title: "PAGE_TITLE.PAYMENTS",
			validateRoute: [routeCheckLoggedIn, routeCheckHasContracts],
			componentOverride: [routeOverrideComponentErrorProfile, routeOverrideComponentErrorFetchContracts],
		},
	},
	{
		path: "/payments",
		component: lazy(() => import("../../pages/Payments")),
		meta: {
			title: "PAGE_TITLE.PAYMENTS",
			validateRoute: [routeCheckLoggedIn, routeCheckHasContracts],
			componentOverride: [routeOverrideComponentErrorProfile, routeOverrideComponentErrorFetchContracts],
		},
	},
	{
		path: "/contact-profile",
		component: lazy(() => import("../../pages/ContactProfile")),
		meta: {
			title: "PAGE_TITLE.CONTACT_PROFILE",
			validateRoute: [routeCheckLoggedIn, routeCheckHasContracts],
			componentOverride: [routeOverrideComponentErrorProfile, routeOverrideComponentErrorFetchContracts],
		},
	},
	{
		path: "/add-property",
		component: lazy(() => import("../../pages/AddProperty")),
		meta: {
			title: "PAGE_TITLE.ADD_PROPERTY",
			validateRoute: routeCheckLoggedIn,
			componentOverride: routeOverrideComponentErrorProfile,
		},
	},
	{
		path: ["/request-service/:currentStep", "/request-service"],
		exact: true,
		component: lazy(() => import("../../pages/RequestService")),
		meta: {
			title: "PAGE_TITLE.REQUEST_SERVICE",
		},
	},
	{
		path: ["/cashout/:cashoutID", "/cashout/:cashoutID/:step", "/cashout/:cashoutID/:step/confirmation"],
		exact: true,
		component: lazy(() => import("../../pages/Cashout")),
		meta: {
			title: "PAGE_TITLE.CASHOUT",
			validateRoute: [routeCheckLoggedIn, routeCheckHasContracts],
			componentOverride: [routeOverrideComponentErrorProfile, routeOverrideComponentErrorFetchContracts],
		},
	},
	{
		path: "/add-property-confirm/:token",
		component: lazy(() => import("../../pages/AddPropertyConfirm")),
		meta: {
			title: "PAGE_TITLE.ADD_PROPERTY_CONFIRM",
		},
	},
	{
		path: "/dispatch/:dispatchID/non-covered-charges-estimate",
		component: lazy(() => import("../../pages/NonCoveredCharges")),
		meta: {
			title: "PAGE_TITLE.NON_COVERED_COSTS",
		},
	},
	{
		path: "/dispatch/:dispatchID",
		component: lazy(() => import("../../pages/DispatchDetails")),
		meta: {
			title: "PAGE_TITLE.DISPATCH_STATUS",
		},
	},
	{
		path: "/payment-terms-and-conditions",
		component: () => <GenericTermsAndConditions zestyContentKey="paymentTermsAndConditions" />,
		meta: {
			title: "PAGE_TITLE.PAYMENT_TERMS_AND_CONDITIONS",
		},
	},
	{
		path: "/request-service-terms-and-conditions",
		component: () => <GenericTermsAndConditions zestyContentKey="requestServiceTermsAndConditions" />,
		meta: {
			title: "PAGE_TITLE.REQUEST_SERVICE_TERMS_AND_CONDITIONS",
		},
	},
	{
		path: "/request-service-payment-terms-and-conditions",
		component: () => <GenericTermsAndConditions zestyContentKey="tsfTermsAndConditions" />,
		meta: {
			title: "PAGE_TITLE.REQUEST_SERVICE_PAYMENT_TERMS_AND_CONDITIONS",
		},
	},
	{
		path: "/request-service-maid-service-terms-and-conditions",
		component: () => <GenericTermsAndConditions zestyContentKey="maidServiceTermsAndConditions" />,
		meta: {
			title: "PAGE_TITLE.MAID_SERVICE_TERMS_AND_CONDITIONS",
		},
	},
	{
		path: "/request-service-pest-service-terms-and-conditions",
		component: () => <GenericTermsAndConditions zestyContentKey="pestServiceTermsAndConditions" />,
		meta: {
			title: "PAGE_TITLE.PEST_SERVICE_TERMS_AND_CONDITIONS",
		},
	},
	{
		path: "/request-service-covid-19-statement",
		component: () => <GenericTermsAndConditions zestyContentKey="covid19Statement" />,
		meta: {
			title: "PAGE_TITLE.COVID_19_STATEMENT",
		},
	},
	{
		path: "/request-service-covid-19-statement-contractors",
		component: () => <GenericTermsAndConditions zestyContentKey="covid19StatementContractors" />,
		meta: {
			title: "PAGE_TITLE.COVID_19_STATEMENT",
		},
	},

	// Routes for developers to use for debugging
	{
		path: "/dev",
		component: lazy(() => import("../../pages/Developer")),
	},
	{
		path: "/chat-client-test",
		component: lazy(() => import("../../pages/ChatClientTest")),
	},
	{
		path: "/toggles",
		component: lazy(() => import("../../pages/FeatureToggles")),
	},
	{
		path: "/fusionauth",
		component: lazy(() => import("../../pages/FusionAuthLanding")),
	},
	{
		path: "/sentry-demo",
		component: lazy(() => import("../../pages/SentryDemo")),
	},
	{
		path: "/terms-and-conditions-demo",
		component: lazy(() => import("../../pages/TermsAndConditionsDemo")),
	},
	{
		path: "/update-contracts",
		component: lazy(() => import("../../pages/UpdateContracts")),
	},
	{
		path: "/split",
		component: makeRedirectByLocationComponent(() => "/toggles"),
	},

	// Redirects to appropriate URLs
	{
		path: ["/http:", "/https:"],
		component: makeRedirectByLocationComponent((location) => location.pathname.substr(1)),
	},
	{
		path: "/myaccount-ui",
		component: makeRedirectByLocationComponent((location) => location.pathname.replace("/myaccount-ui", "")),
	},

	// Default 404 page when no other routes match
	{
		path: "/",
		component: lazy(() => import("../../pages/NotFound")),
		meta: {
			title: "PAGE_TITLE.PAGE_NOT_FOUND",
		},
	},
]

export default routes
