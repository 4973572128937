import React from "react"
import { useDispatch } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import Alert from "../Alert"
import { AlertActions } from "../../store/alert/actions"
import { usePilotGroup } from "../../hooks"
import { useUserAlerts } from "../../store/zesty/selectors"
import { useCurrentContractID } from "../../store/contract/selectors"
import { canShowUserAlert } from "./utils"
import { useSuppressedPromos } from "../../store/user/selectors"
import { useIsServiceable, useSessionUUID } from "../../store/requestService/selectors"

const UserAlert = ({ userAlert, isAboveHeader }) => {
	const history = useHistory()
	const location = useLocation()

	const dispatch = useDispatch()
	const userAlerts = useUserAlerts()
	const contractID = useCurrentContractID()
	const sessionUUID = useSessionUUID()
	const suppressedPromos = useSuppressedPromos()
	const isServiceable = useIsServiceable()

	const { isInPilotGroup } = usePilotGroup(userAlert.pilotGroupType, contractID, sessionUUID)

	if (!canShowUserAlert(userAlert, userAlerts, isInPilotGroup, suppressedPromos, isServiceable, location)) {
		return null
	}

	if (userAlert.suppressible && !userAlert.promo) {
		return (
			<Alert
				alert={{
					id: userAlert.id,
					options: {
						variant: isAboveHeader ? "inline" : "floating",
						type: "warning",
						message: `User alert defined as suppressible without a promo field! Please enter promo field for user alert: ${userAlert.message}`,
					},
				}}
			/>
		)
	} else {
		return (
			<Alert
				alert={{
					id: userAlert.id,
					options: {
						id: userAlert.id,
						variant: isAboveHeader ? "inline" : "floating",
						type: userAlert.alertType,
						message: userAlert.message,
						closeButton: userAlert.suppressible && userAlert.promo,
						onCloseAlert: () => dispatch(AlertActions.suppressPromo(userAlert.promo)),
						button: userAlert.serviceItemID &&
							isServiceable && {
								text: userAlert.buttonLabel,
								onClick: (event) => {
									event.preventDefault()

									history.push("/request-service", {
										recallItem: {
											serviceItemID: userAlert.serviceItemID,
										},
									})
								},
							},
					},
				}}
			/>
		)
	}
}

export default UserAlert
