import React, { useEffect, useState, useCallback } from "react"
import { isDesktop, applyTranslations } from "./utils"
import ContractService from "./services/contract"
import { useLanguage, useLocalizedText, useTextTemplatesByLanguage } from "@ftdr/blueprint-components-react"

export const useLocalizedContent = () => {
	const language = useLanguage()
	const textTemplatesByLanguage = useTextTemplatesByLanguage()
	const localizedContent = useCallback(
		(content) => applyTranslations(content, textTemplatesByLanguage, language),
		[textTemplatesByLanguage, language]
	)
	return localizedContent
}

// TODO - Look into replacement for isMobile, isDesktop,useDesktop with BDS Screen size context provider
export const useIsDesktop = () => {
	const [value, setValue] = useState(isDesktop())

	useEffect(() => {
		const resizeHandler = () => {
			setValue(isDesktop())
		}

		window.addEventListener("resize", resizeHandler)

		return () => {
			window.removeEventListener("resize", resizeHandler)
		}
	}, [])

	return value
}

export const usePilotGroup = (pilotGroupType, contractID, sessionUUID) => {
	const [isInPilotGroup, setInPilotGroup] = useState(false)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		if (!pilotGroupType) {
			setLoading(false)
			setInPilotGroup(false)
			return
		}

		setLoading(true)

		if (!contractID) {
			return
		}

		let cancelled = false

		ContractService.checkPilotGroup(contractID, pilotGroupType, sessionUUID)
			.then((res) => {
				if (!cancelled) {
					setInPilotGroup(res)
					setLoading(false)
				}
			})
			.catch((err) => {
				if (!cancelled) {
					setInPilotGroup(false)
					setLoading(false)
				}
			})

		return () => (cancelled = true)
	}, [pilotGroupType, contractID, sessionUUID])

	return { loading, isInPilotGroup }
}

export const useFieldErrors = () => {
	const localizedText = useLocalizedText()
	const [fieldErrors, setFieldErrors] = useState([])

	const addFieldError = (field, message) => {
		setFieldErrors(fieldErrors.filter((error) => error.field !== field).concat([{ field, message }]))
	}

	const hasFieldError = (field) => {
		return fieldErrors.some((error) => error.field === field)
	}

	const getFieldError = (field) => {
		const fieldError = fieldErrors.find((error) => error.field === field)
		return fieldError ? localizedText(fieldError.message) : ""
	}

	const clearFieldError = (field) => {
		if (hasFieldError(field)) {
			setFieldErrors(fieldErrors.filter((error) => error.field !== field))
		}
	}

	const resetFieldErrors = () => setFieldErrors([])

	return {
		fieldErrors,
		addFieldError,
		hasFieldError,
		getFieldError,
		clearFieldError,
		resetFieldErrors,
	}
}
