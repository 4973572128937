import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { getContractCustomerID } from "../../utils"

export const selectIsLoadingContracts = (state) => state.contract.loading
export const selectErrorLoadingContracts = (state) => state.contract.fetchError
export const selectCurrentContract = (state) => state.contract.current
export const selectCurrentContractID = (state) => state.contract.current?.contractID
export const selectCurrentCustomerID = createSelector(
	[(state) => state.user.profile?.contractCustomer, (state) => state.contract.current?.contractID],
	(contractCustomer, currentContractID) => getContractCustomerID(contractCustomer, currentContractID)
)
export const selectContractList = (state) => state.contract.list
export const selectNumContracts = (state) => state.contract.list.length
export const selectIsDownloadingContract = (state) => state.contract.isDownloadingContract
export const selectIsAddingProperty = (state) => state.contract.isAddingProperty

export const useIsLoadingContracts = () => useSelector(selectIsLoadingContracts)
export const useErrorLoadingContracts = () => useSelector(selectErrorLoadingContracts)
export const useCurrentContract = () => useSelector(selectCurrentContract)
export const useCurrentContractID = () => useSelector(selectCurrentContractID)
export const useCurrentCustomerID = () => useSelector(selectCurrentCustomerID)
export const useContractList = () => useSelector(selectContractList)
export const useNumContracts = () => useSelector(selectNumContracts)
export const useHasMultipleContracts = () => useSelector(selectNumContracts) > 1
export const useIsDownloadingContract = () => useSelector(selectIsDownloadingContract)
export const useIsAddingProperty = () => useSelector(selectIsAddingProperty)
