import React, { useMemo } from "react"
import Alert from "./Alert"
import UserAlert from "./UserAlert"
import { useAlertList } from "../store/alert/selectors"
import { useUserAlerts } from "../store/zesty/selectors"

const Alerts = ({ isAboveHeaderOnDesktop, isAboveHeaderOnMobile }) => {
	const alerts = useAlertList()
	const userAlerts = useUserAlerts()

	const userAlertsAboveHeader = useMemo(() => userAlerts.filter((userAlert) => userAlert.showAboveHeader), [userAlerts])
	const userAlertsInPage = useMemo(() => userAlerts.filter((userAlert) => !userAlert.showAboveHeader), [userAlerts])

	return (
		<div id="alerts">
			{isAboveHeaderOnDesktop && (
				<div className="hidden md:block">
					{userAlertsAboveHeader.map((userAlert, i) => (
						<UserAlert key={i} userAlert={userAlert} isAboveHeader={true} />
					))}
				</div>
			)}
			{isAboveHeaderOnMobile && (
				<div className="md:hidden">
					{userAlertsAboveHeader.map((userAlert, i) => (
						<UserAlert key={i} userAlert={userAlert} isAboveHeader={false} />
					))}
				</div>
			)}

			{!isAboveHeaderOnDesktop && !isAboveHeaderOnMobile && (
				<>
					{alerts.map((alert) => (
						<Alert key={alert.id} alert={alert} />
					))}

					{userAlertsInPage.map((userAlert, i) => (
						<UserAlert key={i} userAlert={userAlert} isAboveHeader={false} />
					))}
				</>
			)}
		</div>
	)
}

export default Alerts
