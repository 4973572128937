import React, { useMemo, useState } from "react"
import classNames from "classnames"
import { Alert as AlertModel } from "../../store/alert/state"
import { Notification, useLocalizedText } from "@ftdr/blueprint-components-react"
import AlertAction from "./AlertAction"

const Alert = ({
	// Object to represent the alert, should match up options to `AlertModel` in the alert store, or it can be an `AlertModel` instance
	alert: alertProp,
}) => {
	const localizedText = useLocalizedText()

	const [isClosed, setClosed] = useState(false)

	const alert = useMemo(() => new AlertModel(alertProp), [alertProp])

	const buttons = useMemo(() => {
		if (Array.isArray(alert.options.button)) {
			return alert.options.button
		} else if (alert.options.button) {
			return [alert.options.button]
		} else {
			return []
		}
	}, [alert])

	const handleCloseButton = (event) => {
		event.preventDefault()

		if (typeof alert.options.onCloseAlert === "function") {
			alert.options.onCloseAlert(event)
		}

		setClosed(true)
	}

	if (isClosed) {
		return null
	}

	return (
		<Notification
			id={alert.id}
			className={classNames(alert.options.variant !== "inline" && "mb-5")}
			variant={alert.options.variant ?? "floating"}
			status={alert.options.type}
			showStatusLabel={false}
			matchColor={alert.options.matchColor}
			showCloseButton={Boolean(alert.options.closeButton)}
			onClose={(event) => handleCloseButton(event)}
			actions={
				buttons.length > 0 && (
					<div className="flex flex-wrap gap-2 ml-5">
						{buttons.map((button) => (
							<AlertAction alert={alert} button={button} onCloseClick={handleCloseButton} />
						))}
					</div>
				)
			}
		>
			{typeof alert.options.message === "string" ? (
				<span
					dangerouslySetInnerHTML={{
						__html: localizedText(alert.options.message, alert.options.messageData),
					}}
				></span>
			) : (
				alert.options.message
			)}
		</Notification>
	)
}

export default Alert
