import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import Alerts from "../../components/Alerts"
import Page from "../../components/Page"
import ErrorPage from "../../pages/Error"
import Loading from "../../pages/Loading"
import AuthService from "../../services/auth"
import { ContractActions } from "../../store/contract/actions"
import { UserActions } from "../../store/user/actions"
import PageRoute from "./PageRoute"
import { useIsLoggedIn } from "../../store/user/selectors"
import { useHasUserAlertOnLogin } from "../../store/zesty/selectors"

const UserPageRoute = ({ route }) => {
	const dispatch = useDispatch()
	const isLoggedIn = useIsLoggedIn()
	const hasUserAlertBlock = useHasUserAlertOnLogin()

	const [startedLoggingIn, setStartedLoggingIn] = useState(false)
	const [errorLoggingIn, setErrorLoggingIn] = useState(false)
	const [doneLoggingIn, setDoneLoggingIn] = useState(false)

	useEffect(() => {
		if (!isLoggedIn && !hasUserAlertBlock && !startedLoggingIn) {
			setStartedLoggingIn(true)

			AuthService.getClient()
				.getUser()
				.then((user) => {
					if (!user) return

					if (user.expires_in <= 0) {
						AuthService.getClient().removeUser()
						return
					}

					return dispatch(UserActions.login()).then((isLoggedIn) => {
						if (!isLoggedIn) return

						return dispatch(ContractActions.fetchContracts()).then((fetchedContracts) => {
							if (!fetchedContracts) {
								throw new Error("Could not fetch contracts")
							}
						})
					})
				})
				.then(() => {
					setDoneLoggingIn(true)
				})
				.catch((error) => {
					console.error(error)
					setErrorLoggingIn(true)
				})
		}
	}, [isLoggedIn, hasUserAlertBlock, startedLoggingIn])

	if (hasUserAlertBlock) {
		return (
			<Page>
				<div className="container mx-auto">
					<Alerts />
				</div>
			</Page>
		)
	} else if (errorLoggingIn) {
		return (
			<Page>
				<div className="container mx-auto">
					<Alerts />
				</div>

				<ErrorPage />
			</Page>
		)
	} else if ((!isLoggedIn && !startedLoggingIn) || (startedLoggingIn && !doneLoggingIn)) {
		return <Loading />
	} else {
		return <PageRoute route={route} />
	}
}

export default UserPageRoute
