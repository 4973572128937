import GenericService from "./generic"
import moment from "moment-timezone"
import { capitalizeWords, stringifyError } from "../utils"

const SERVICE_NAME = "serviceRequest"

// TYPES DEFINITION START

/**
 * Service Item model
 * @typedef {Object} ServiceItem
 * @property {string} serviceItemID
 * @property {string} description
 * @property {string} brand
 * @property {string} tradeCode
 * @property {Symptom} symptom
 * @property {string} modelNumber
 * @property {number} quantity
 * @property {number} price
 * @property {string} location
 * @property {string} serialNumber
 * @property {string} category
 * @property {Symptom[]} symptoms
 */

/**
 * Service Item model
 * @typedef {Object} Symptom
 * @property {string} ID
 * @property {string} description
 */

/**
 * Service Item Categories model
 * @typedef {Object} ServiceItemCategory
 * @property {string} description
 * @property {ServiceItem[]} serviceItems
 */

/**
 * Contract eligible endpoint response model
 * @typedef {Object} ContractEligibleResponse
 * @property {boolean} serviceable
 * @property {string} serviceableError
 */

/**
 * Pricing endpoint response model
 * @typedef {Object} PricingResponse
 * @property {number} serviceFee
 * @property {number} tax
 * @property {boolean} collectible
 * @property {boolean} hasPricesPerItem
 * @property {*[]} serviceItems
 */

// TYPES DEFINITION END

export const FALLOUT_PAGES = {
	SelectItem: "SelectItem",
	SelectedItems: "SelectedItems",
	Contractor: "Contractor",
	Payments: "Payments",
	Confirmation: "Confirmation",
}

export const FALLOUT_EVENTS = {
	Next: "Next",
	Previous: "Previous",
	Cancel: "Cancel",
	Closed: "Closed",
	Abandoned: "Abandoned",
	Success: "Success",
	Error: "Error",
}

export const FALLOUT_PAYMENT_METHODS = {
	OneTimeCC: "OneTimeCC",
	OneTimeACH: "OneTimeACH",
	OneTimePAYPAL: "OneTimePAYPAL",
	StoredMethod: "StoredMethod",
	NoPaymentNeeded: "NoPaymentNeeded",
}

const handleCoveredItemsResponse = (response) => {
	if (response.serviceItemCategories) {
		response.serviceItemCategories.forEach((category) => {
			if (category.subCategories.length) {
				category.subCategories.forEach((subCategory) => {
					subCategory.serviceItems.sort((a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()))
				})
			} else {
				category.serviceItems.sort((a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()))
			}
		})

		return response.serviceItemCategories
	} else if (response.serviceItems) {
		return response.serviceItems.sort((a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()))
	} else {
		return []
	}
}

class RequestService extends GenericService {
	constructor() {
		super(SERVICE_NAME)

		this.useAccessTokenInAxios()
		this.useCheckValidateContractAccessErrorInResponse()
	}

	/**
	 * whether a contract can request a service or not
	 * @param contractID
	 * @return {Promise<ContractEligibleResponse>}
	 */
	async getServiceability(contractID) {
		return await this.axios.get(`/my-account/request-service/contract-eligible/${contractID}`)
	}

	/**
	 * fetch covered items per contractID
	 * @param contractID
	 * @return {Promise<ServiceItem[]>}
	 */
	async fetchServiceItems(contractID, categories = false) {
		const response = await this.axios.get(`/my-account/${contractID}/covered-items`, {
			params: {
				categories: Boolean(categories),
			},
			validateStatus: (status) => (status >= 200 && status < 300) || status === 404,
		})

		return handleCoveredItemsResponse(response)
	}

	/**
	 * Fetch grouped service item categories
	 * @param contractID
	 * @param sessionUUID
	 * @return {Promise<ServiceItemCategory[]>}
	 */
	async fetchServiceItemCategories(contractID, sessionUUID, subcategories = false) {
		const response = await this.axios.get(`/my-account/request-service/${contractID}/covered-items`, {
			params: {
				session_id: sessionUUID,
				subcategories,
			},
			validateStatus: (status) => (status >= 200 && status < 300) || status === 404,
		})

		return {
			serviceItemCategories: handleCoveredItemsResponse(response),
			sessionExpiration: response.sessionExpiration,
		}
	}

	async lookupContract(searchOptions) {
		const response = await this.axios.post("/my-account/request-service/search", searchOptions)

		response.contracts = response.contracts.map((contract) => {
			contract.property.streetAddress = capitalizeWords(contract.property.streetAddress)
			contract.property.streetAddress2 = capitalizeWords(contract.property.streetAddress2)
			contract.property.city = capitalizeWords(contract.property.city)
			return contract
		})

		return response
	}

	getServiceItemOptions(contractID, sessionUUID, serviceItem) {
		return this.axios.post(`/my-account/request-service/covered-item-details`, {
			contractID,
			sessionUUID,
			serviceItem,
		})
	}

	/**
	 * fetch schedule options
	 * @param {{contractID: string|number, sessionUUID: string, selectedItems: *[]}} params
	 * @return {Promise}
	 */

	async fetchScheduleOptions({ contractID, sessionUUID, selectedItems, dwellingTypeCode }) {
		const data = {
			contractID,
			sessionUUID,
			serviceItems: selectedItems,
		}

		if (dwellingTypeCode) {
			data.dwellingTypeCode = dwellingTypeCode
		}

		const response = await this.axios.post("/my-account/request-service/contractors", data)

		return {
			contractorOptions: response.contractorOptions,
			scheduleOptions: response.scheduleOptions.map((schedule) => {
				return {
					...schedule,
					startTimestamp: moment.unix(schedule.startTimestamp).utc(),
					endTimestamp: moment.unix(schedule.endTimestamp).utc(),
				}
			}),
			sessionExpiration: response.sessionExpiration,
		}
	}

	/**
	 * get request service pricing
	 * @param {string|number} contractID
	 * @param {*[]} selectedServiceItems
	 * @param {boolean} overrideRecallRules
	 * @return {Promise<PricingResponse>}
	 */
	getPricing({ contractID, sessionUUID, selectedServiceItems, overrideRecallRules }) {
		return this.axios.post("/my-account/request-service/service-fee", {
			contractID,
			sessionUUID,
			serviceItems: selectedServiceItems,
			forceNewWorkOrder: overrideRecallRules,
		})
	}

	submit(payload) {
		return this.axios.post("/my-account/request-service/submit", payload)
	}

	sendFalloutReport(contractID, sessionUUID, data) {
		if (data?.error) {
			data.error = stringifyError(data.error)
		}

		return this.axios.post(`/my-account/request-service/fallout/${contractID}`, data, {
			params: {
				session_id: sessionUUID,
			},
		})
	}

	resetSessionExpiration(contractID, sessionUUID, tenant) {
		return this.axios.post(`/my-account/request-service/session`, {
			contractID: contractID,
			sessionUUID: sessionUUID,
			tenant: tenant,
		})
	}

	sendStreemEvent(contractID, sessionUUID, data) {
		return this.axios.post(`/my-account/request-service/streem/${contractID}?session_id=${sessionUUID}`, data)
	}

	registerUser(registrationInfo) {
		return this.axios.post(`/my-account/customer/pending-user`, registrationInfo)
	}
}

export default new RequestService()
