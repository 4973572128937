import moment from "moment-timezone"
import ConfigService from "./services/config"
import AuthService from "./services/auth"
import Cookies from "universal-cookie"
import jwtDecode from "jwt-decode"
import { Badge } from "@ftdr/blueprint-components-react"
import { common } from "@ftdr/myaccount-js-client"
import { walletpb } from "@ftdr/wallet-js-client"

const cookies = new Cookies()

export const GLOBAL_HEADER_MOBILE_HEIGHT = 80

export const SITE_NAME_AHS = "ahs"
export const SITE_NAME_HSA = "hsa"
export const SITE_NAME_FTDR = "ftdr"

export const CUSTOMER_FIRST_NAME_MAX_LENGTH = 20
export const CUSTOMER_LAST_NAME_MAX_LENGTH = 17
export const CUSTOMER_EMAIL_MAX_LENGTH = 500
export const LINE_ITEM_MODEL_MAX_LENGTH = 40
export const LINE_ITEM_SYMPTOM_MAX_LENGTH = 34

export const QUALTRICS_AUTO_RENEW_OPT_OUT = "autorenewoptout"

export const getSiteName = () => ConfigService.config?.site?.name
export const getBrandLong = () => ConfigService.config?.site?.brandLong
export const getBrandShort = () => ConfigService.config?.site?.brandShort

export const SITE_DOMAIN_MAP = {
	[SITE_NAME_AHS]: ".ahs.com",
	[SITE_NAME_HSA]: ".myhomewarranty.com",
}

export const getDomain = () => SITE_DOMAIN_MAP[getSiteName()]

export const isSiteAHS = () => getSiteName() === SITE_NAME_AHS
export const isSiteHSA = () => getSiteName() === SITE_NAME_HSA
export const isSiteFTDR = () => getSiteName() === SITE_NAME_FTDR

export const siteNameToTenant = (siteName = "") => {
	if (!siteName) siteName = getSiteName()
	if (typeof siteName !== "string") return common.Tenant.TENANT_UNDEFINED
	return common.Tenant[siteName.toUpperCase()] || common.Tenant.TENANT_UNDEFINED
}

export const pageTitle = (title) => {
	return title + (ConfigService.config.site.brandLong ? ` | ${ConfigService.config.site.brandLong}` : "")
}

export const setPageTitle = (title, useFormatter = true) => {
	document.title = useFormatter ? pageTitle(title) : title
}

export const isObject = (obj) => Object.prototype.toString.call(obj) === "[object Object]"

export const extend = (target, ...sources) => {
	if (!isObject(target)) {
		target = {}
	}

	sources.forEach((source) => {
		if (isObject(source)) {
			Object.keys(source).forEach((key) => {
				if (key in target && isObject(target[key]) && isObject(source[key])) {
					extend(target[key], source[key])
				} else if (isObject(source[key])) {
					target[key] = extend({}, source[key])
				} else {
					target[key] = source[key]
				}
			})
		}
	})

	return target
}

export const applyTranslations = (content, translationsByLanguage, language) => {
	if (!content || !translationsByLanguage || !language || !(language in translationsByLanguage)) {
		return content
	}

	const translations = translationsByLanguage[language]

	return String(content).replace(/\{i18n:([^}]+)\}/g, (match, key) => {
		if (translations[key]) {
			return translations[key]
		} else {
			return match
		}
	})
}

export const makeCancelable = (promise) => {
	let canceled = false

	const wrappedPromise = new Promise((resolve, reject) => {
		promise
			.then((value) => {
				if (!canceled) {
					resolve(value)
				} else {
					const error = new Error(`Promise has been canceled.`)
					error.canceled = true
					reject(error)
				}
			})
			.catch((error) => {
				if (!canceled) {
					reject(error)
				} else {
					const error = new Error(`Promise has been canceled.`)
					error.canceled = true
					reject(error)
				}
			})
	})

	return {
		promise: wrappedPromise,
		cancel: () => {
			canceled = true
		},
	}
}

export const getPhoneNumberDigits = (phoneNumber) => (typeof phoneNumber === "string" && phoneNumber.replace(/[^\d]/g, "").length) || 0

export const isValidPhoneNumber = (phoneNumber) => getPhoneNumberDigits(phoneNumber) === 10

export const arePhoneNumbersEqual = (a, b) =>
	typeof a === "string" && typeof b === "string" && a.replace(/[^\d]/g, "") === b.replace(/[^\d]/g, "")

export const isValidEmailAddress = (email) =>
	/^[^@.\s]+[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]*@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]*[a-zA-Z0-9]+$/.test(email)

export const capitalizeWords = (str) => str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())

export const isMobile = () => window.innerWidth < 768

export const isDesktop = () => !isMobile()

export const PAYMENT_METHOD_TYPE_CARD = "CARD"
export const PAYMENT_METHOD_TYPE_BANK = "ACH"
export const PAYMENT_METHOD_TYPE_PAYPAL = "PAYPAL"

export const setLoginRedirect = (url) => {
	// default to current URL
	url = url ?? window.location.href

	window.sessionStorage.setItem("loginRedirect", url)

	// Also store in a cookie so that it can persist across feature branch & non-feature-branch envs
	const expires = new Date()
	expires.setHours(expires.getHours() + 8)
	cookies.set("loginRedirect", url, { path: "/", expires, domain: window.location.host })
}
export const getLoginRedirect = () => window.sessionStorage.getItem("loginRedirect") ?? cookies.get("loginRedirect")
export const unsetLoginRedirect = () => {
	window.sessionStorage.removeItem("loginRedirect")
	cookies.remove("loginRedirect")
}

export const getAuthServiceConfigWithLoginRedirect = () => {
	let config
	const loginRedirect = getLoginRedirect()
	if (loginRedirect) {
		config = {
			extraQueryParams: {
				original_redirect_uri: loginRedirect,
			},
		}
	}
	return config
}

export const getPaymentMethodText = (paymentMethod, localizedText) => {
	switch (paymentMethod.type) {
		case PAYMENT_METHOD_TYPE_CARD: {
			return localizedText("PAYMENT_METHODS.DISPLAY_TEXT_CARD", {
				cardType: paymentMethod.cardType,
				cardNumberLastFour: paymentMethod.cardNumber ?? paymentMethod.cardNumberLastFour,
			})
		}
		case PAYMENT_METHOD_TYPE_BANK: {
			return localizedText("PAYMENT_METHODS.DISPLAY_TEXT_ACH", {
				accountNumberLastFour: paymentMethod.accountNumber.slice(-4),
			})
		}
		case walletpb.PaymentMethodType.Paypal:
		case PAYMENT_METHOD_TYPE_PAYPAL: {
			return localizedText("PAYMENT_METHODS.DISPLAY_TEXT_PAYPAL")
		}
		case walletpb.PaymentMethodType.CreditCard: {
			return localizedText("PAYMENT_METHODS.DISPLAY_TEXT_CARD", {
				cardType: getCardBrandNameShort(paymentMethod.cc.brand),
				cardNumberLastFour: paymentMethod.cc.last4,
			})
		}
		case walletpb.PaymentMethodType.ACH: {
			return localizedText("PAYMENT_METHODS.DISPLAY_TEXT_ACH", {
				accountNumberLastFour: paymentMethod.ach.accountNumber.slice(-4),
			})
		}
		case walletpb.PaymentMethodType.Undefined_PaymentMethodType:
		default: {
			return "UNKNOWN PAYMENT METHOD TYPE"
		}
	}
}

export const getCardBrandNameShort = (creditCardBrand) => {
	const creditCardBrands = {
		[walletpb.CreditCardBrand.Visa]: "VISA",
		[walletpb.CreditCardBrand.MasterCard]: "MC",
		[walletpb.CreditCardBrand.AmericanExpress]: "AMEX",
		[walletpb.CreditCardBrand.Discover]: "DSC",
	}

	return creditCardBrands[creditCardBrand] ?? "CARD"
}

class StorageHelper {
	constructor(key) {
		this.key = key
	}

	set = (object) => sessionStorage.setItem(this.key, JSON.stringify(object))

	get = () => JSON.parse(sessionStorage.getItem(this.key))

	remove = () => sessionStorage.removeItem(this.key)

	exists = () => Boolean(sessionStorage.getItem(this.key))
}

export class StorageHelperFactory {
	static storageHelperMap = new Map()

	static create(key) {
		if (this.storageHelperMap.has(key)) {
			return this.storageHelperMap.get(key)
		} else {
			const storageHelper = new StorageHelper(key)
			this.storageHelperMap.set(key, storageHelper)
			return this.storageHelperMap.get(key)
		}
	}
}

/**
 * Find customerID given a contractID
 * @param {*[]} contractCustomer
 * @param {string} contractID
 * @return {string}
 */
export const getContractCustomerID = (contractCustomer, contractID) => {
	return contractCustomer?.find((item) => item.contractID === contractID)?.customerID
}

export const stringifyError = (error) => {
	if (!error) {
		return "NO_ERROR"
	} else if (typeof error === "string") {
		return error
	} else if (error instanceof Error) {
		return error.toString()
	} else if (error.isAxiosError) {
		const endpoint = `${error.config.method} ${error.config.baseURL}${error.config.url}`
		if (error.response && error.response.statusText !== "") {
			return `Axios Response for ${endpoint}: HTTP ${error.response.status}: ${error.response.statusText}`
		} else if (error.response) {
			return `Axios Response for ${endpoint}: ${getAxiosErrorMessage(error)}`
		} else {
			return `Axios No Response for ${endpoint}`
		}
	} else {
		return JSON.stringify(error)
	}
}

export const getAxiosErrorMessage = (axiosError) => {
	const errors = axiosError?.response?.data?.errors
	return (errors || [{ message: axiosError.message }]).map((error) => error.message).join(", ")
}

export const SetMyAccountCookie = (isLoggedIn, profile) => {
	//cookie should expire after 6 months.
	const inSixMonths = new Date()
	inSixMonths.setMonth(inSixMonths.getMonth() + 6)
	const options = { path: "/", expires: inSixMonths, domain: ".ahs.com" }

	cookies.set("ue", "ma", options)
	if (isLoggedIn) {
		cookies.set("existingCustomer", "true", options)
		if (profile) {
			cookies.set("memberName", profile.firstName, options)
		}
	}
}

export const getDispatchStatusDescriptionString = (dispatchStatus) => {
	let description = dispatchStatus.statusDescription

	if (dispatchStatus.subStatusDescription) {
		description += " - " + dispatchStatus.subStatusDescription
	}

	return description
}

export const getBadgeForCashoutStatus = (cashoutStatus, localizedText) => {
	if (!cashoutStatus) {
		return ""
	}

	const cashOutBadgeColors = {
		APPROVED: "success",
		INITIATED: "gray",
		DISBURSED: "success",
		CANCELLED: "warning",
		OFFERED: "primary",
		ACCEPTED: "success",
		DECLINED: "secondary",
		AUTOMATED_CHECK: "success",
	}

	const cashOutStatusToUpperCase = cashoutStatus.toUpperCase()

	return (
		<Badge
			color={cashOutBadgeColors[cashOutStatusToUpperCase] ?? "gray"}
			className="w-full rounded-2 font-bold text-center"
			size="small"
		>
			{cashOutStatusToUpperCase === "AUTOMATED_CHECK"
				? localizedText("CASHOUT.CASHOUT_AUTOMATED_CHECK")
				: capitalizeWords(cashoutStatus)}
		</Badge>
	)
}

export const setAccessTokenToCookie = async () => {
	const user = await AuthService.getClient().getUser()
	const refreshTokenDuration = AuthService.getRefreshTokenDuration()
	// Set access token to cookie for renewals flow.
	if (user) {
		const accessToken = jwtDecode(user.access_token)
		const accessTokenExpiryDate = new Date(accessToken.exp * 1000)
		const refreshTokenExpiryDate = new Date((accessToken.iat + refreshTokenDuration) * 1000)
		const domain = getDomain()

		// Need to omit domain for localhost
		if (window.location.origin.includes("localhost")) {
			cookies.set("accessToken", user.access_token, { expires: accessTokenExpiryDate })
			cookies.set("refreshToken", user.refresh_token, { expires: refreshTokenExpiryDate })
		} else {
			cookies.set("accessToken", user.access_token, { domain: domain, expires: accessTokenExpiryDate })
			cookies.set("refreshToken", user.refresh_token, { domain: domain, expires: refreshTokenExpiryDate })
		}

		// Support renewals feature branch testing in lower envs
		if (ConfigService.config?.env === "local" || ConfigService.config?.env === "dev" || ConfigService.config?.env === "test") {
			cookies.set("accessToken", user.access_token, { domain: ".ftdrinternal.com", expires: accessTokenExpiryDate })
			cookies.set("refreshToken", user.refresh_token, { domain: ".ftdrinternal.com", expires: refreshTokenExpiryDate })
		}
	}
}

export const removeAccessTokenCookies = () => {
	cookies.remove("accessToken")
	cookies.remove("refreshToken")
}

export const setCurrentContractIdToCookie = async (currentContractId) => {
	const user = await AuthService.getClient().getUser()
	// Set access token to cookie for renewals flow.
	if (user) {
		const expiryDate = new Date(jwtDecode(user.access_token).exp * 1000)
		const domain = getDomain()

		// Need to omit domain for localhost
		if (window.location.origin.includes("localhost")) {
			cookies.set("currentContractID", currentContractId, { expires: expiryDate })
		} else {
			cookies.set("currentContractID", currentContractId, { domain: domain, expires: expiryDate })
		}

		// Support renewals feature branch testing in lower envs
		if (ConfigService.config?.env === "local" || ConfigService.config?.env === "dev" || ConfigService.config?.env === "test") {
			cookies.set("currentContractID", currentContractId, { domain: ".ftdrinternal.com", expires: expiryDate })
		}
	}
}

export const removeCurrentContractCookie = () => {
	cookies.remove("currentContractID")
}

export const populateTemplate = (template, data) => {
	return Object.entries(data).reduce((template, [key, value]) => {
		return template.replace(new RegExp(`{${key}}`, "g"), value)
	}, template)
}

export const removePhoneFormat = (formattedPhone) => formattedPhone.replace(/\D/g, "")

export const phoneLinkFromFormattedPhone = (formattedPhone) => `tel:${removePhoneFormat(formattedPhone)}`

export const STANDARD_TIMEZONE = "America/Chicago"

export const getFormattedCommonDate = (commonDate) => {
	// From common.proto
	// Year of the date. Must be from 1 to 9999, or 0 to specify a date without a year.
	// Month of a year. Must be from 1 to 12, or 0 to specify a year without a month and day.
	// Day of a month. Must be from 1 to 31 and valid for the year and month, or 0 to specify a year by itself or a year and month.
	const year = commonDate?.year
	const month = commonDate?.month
	const day = commonDate?.day

	if (commonDate?.year) {
		if (commonDate?.month) {
			if (commonDate?.day) {
				return moment({ year, month: month - 1, day }).format("MMM. Do, YYYY")
			} else {
				return moment({ year, month: month - 1 }).format("MMM. YYYY")
			}
		} else {
			return moment({ year }).format("YYYY")
		}
	} else if (commonDate?.month && commonDate?.day) {
		return moment({ month: month - 1, day }).format("MMM. Do")
	}

	return ""
}

// DO NOT USE unless ConfigService has not loaded
export const getEnvironmentFromURL = () => {
	if (window.location.origin.includes("localhost")) {
		return "local"
	} else if (window.location.origin.includes("dev.")) {
		return "dev"
	} else if (window.location.origin.includes("test.")) {
		return "test"
	} else if (window.location.origin.includes("ftdrinternal")) {
		return "test"
	} else if (window.location.origin.includes("staging.")) {
		return "staging"
	} else {
		return "prod"
	}
}

export const presetButtonPropsAlertPrimary = () => ({ variant: "filled" })
export const presetButtonPropsAlertSecondary = () => ({ variant: "outlined" })

export const presetButtonPropsLinkPrimary = () => ({ variant: "ghost" })

export const presetButtonFormsPrimary = () => ({ variant: "filled", size: "medium" })
export const presetButtonFormsSecondary = () => ({ variant: "ghost", size: "medium" })

export const DISPATCH_STATUS_SERVICE_APPOINTMENT_ID = "7-f6e692fcb2-18t5km"
export const DISPATCH_STATUS_SERVICE_APPOINTMENT_CANCELLED_ID = "7-94f3dae4ee-xd42zs"
export const DISPATCH_STATUS_SERVICE_APPOINTMENT_SCHEDULED_ID = "7-fce3c5dc92-bg6hq7"
export const DISPATCH_STATUS_SERVICE_APPOINTMENT_PENDING_ID = "7-929abba5c0-x14rtn"
export const DISPATCH_STATUS_CANCELLED_ID = "7-96a1c1d9cf-xh13zl"
export const DISPATCH_STATUS_CANCELLED = "Service Canceled"
export const DISPATCH_STATUS_COMPLETE_ID = "7-eab8c09eb4-26281w"
export const DISPATCH_STATUS_COMPLETE = "Complete"
export const WORK_ORDER_LINE_STATUS_CANCELLED = "Cancelled"
export const NCC_STATUS_PENDING = "PENDING"
export const APPLIANCE_REPLACEMENT_STATUS_PURCHASED = "PURCHASED"

export const addRenewalsFlowTypeCookie = (flowType) => {
	// Need to omit domain for localhost
	if (window.location.origin.includes("localhost")) {
		cookies.set("flowType", flowType)
	} else {
		const domain = getDomain()
		cookies.set("flowType", flowType, { domain: domain })
	}

	// Support renewals feature branch testing in lower envs
	if (ConfigService.config?.env === "local" || ConfigService.config?.env === "dev" || ConfigService.config?.env === "test") {
		cookies.set("flowType", flowType, { domain: ".ftdrinternal.com" })
	}
}

export const removeRenewalsFlowTypeCookie = () => {
	cookies.remove("flowType")
}

export const SetQualtricsTrackingEvent = (eventName, reload = false) => {
	window._qsie = window._qsie || []
	if (eventName) {
		window._qsie.push(eventName)
	}

	if (reload && window.QSI?.API) {
		// reload API to re-evaluate intercepts without page load
		window.QSI.API.unload()
		window.QSI.API.load()
		window.QSI.API.run()
	}
}

export const getContractNotifications = (contract) => {
	const notifications = []

	if (contract.status !== "A" && contract.status !== "L") {
		notifications.push("EXPIRATION_ALERTS.CONTRACT_IS_NOT_ACTIVE")
	}

	if (contract.expirationInfo) {
		if (contract.expirationInfo.PastClosingDate) {
			notifications.push("EXPIRATION_ALERTS.LISTING_IS_PAST_CLOSING_DATE")
		}

		if (
			!contract.expirationInfo.AlreadyRenewed &&
			!contract.expirationInfo.AutoPay &&
			(contract.expirationInfo.IsExpired || contract.expirationInfo.IsExpiring)
		) {
			if (contract.expirationInfo.ContractStatus === "A") {
				notifications.push(
					contract.expirationInfo.IsExpired ? "EXPIRATION_ALERTS.CONTRACT_IS_EXPIRED" : "EXPIRATION_ALERTS.CONTRACT_IS_EXPIRING"
				)
			} else {
				notifications.push(
					contract.expirationInfo.IsExpired ? "EXPIRATION_ALERTS.LISTING_IS_EXPIRED" : "EXPIRATION_ALERTS.LISTING_IS_EXPIRING"
				)
			}
		}
	}

	if (contract.pastDueAmounts) {
		if (contract.pastDueAmounts.pastDuePremium > 0) {
			notifications.push("PAST_DUE_ALERTS.PREMIUM_NOTIFICATION_MESSAGE")
		}

		if (contract.pastDueAmounts.pastDueServiceFee > 0) {
			notifications.push("PAST_DUE_ALERTS.SERVICE_FEE_NOTIFICATION_MESSAGE")
		}
	}

	return notifications
}

export const getContractStreetAddress = (contract) => {
	if (!contract.property) {
		return ""
	}
	if (contract.property.streetAddress2) {
		return `${contract.property.streetAddress} ${contract.property.streetAddress2}`
	}
	return contract.property.streetAddress
}

// debounceFirst will call the function when an event happens and no delay is present.
// delay is set after each event.
// if no delay is set, and then 3 events happen, the first one will call the function and set a delay, and the next 2 events extend the delay.
// if more events come through while delay is set, it will extend the delay.
// after delay is over, if new events come, repeat the process defined above.
export const debounceFirst = (fn, ms, thisArg = null) => {
	let timer
	return (...args) => {
		if (!timer) fn.apply(thisArg, args)
		clearTimeout(timer)
		timer = setTimeout(() => {
			timer = null
		}, ms)
	}
}

// debounceLast will reset delay after each event.
// multiple events in a row will cause the timer to reset.
// function will be called after the delay is over.
export const debounceLast = (fn, ms, thisArg = null) => {
	let timer
	return (...args) => {
		clearTimeout(timer)
		timer = setTimeout(() => {
			timer = null
			fn.apply(thisArg, args)
		}, ms)
	}
}
