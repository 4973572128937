import React, { useMemo } from "react"
import { Redirect } from "react-router-dom"
import { getLoginRedirect, setLoginRedirect } from "../../utils"
import querystring from "querystring"

const LoginRedirect = (props) => {
	const redirect = useMemo(() => {
		setLoginRedirect()
		return getLoginRedirect()
	}, [])

	const params = querystring.stringify({ redirect })
	const to = `${props.to}?${params}`

	return <Redirect {...props} to={to} />
}

export default LoginRedirect
