import { Ahs2024LogomarkColor, HsaLogoColor, IconMail, IconMessage, IconPhone } from "@ftdr/blueprint-components-react"
import { isSiteAHS } from "../../utils"

export const logoRenderer = (variant, isDesktop) => (isSiteAHS() ? <Ahs2024LogomarkColor /> : <HsaLogoColor />)

export const iconRenderer = (icon) => {
	switch (icon) {
		case "IconMessage":
			return <IconMessage size="22" />
		case "IconMail":
			return <IconMail size="22" />
		case "IconPhone":
			return <IconPhone size="22" />
		default:
			return null
	}
}
