export default {
	loading: false,
	loadingPromise: null,
	eFulfillmentEnabled: null,
	eFulfillmentAccessible: null,
	contactInfo: null,
	saving: false,
	fetchError: false,
	preferenceFetchError: false,
	preferenceSaving: false,
	preferenceLoadingPromise: null,
}
