import React from "react"
import AddressBar from "./AddressBar"
import { Breadcrumbs, Text } from "@ftdr/blueprint-components-react"
import classNames from "classnames"

const PageHeader = ({
	// Title of the page, can be omitted if showAddressSelector is true
	title,
	// Subtitle of the page, must have a title as well
	subTitle,
	// Show address selector for member to change properties, can be omitted if there is a title
	showAddressSelector,
	// To show breadcrumbs, this object would be an object of props to Breadcrumbs component
	breadcrumbs,
	// Show additional content to the side, generally if there is no property selector
	sideContent,
}) => {
	if (!title && !showAddressSelector) {
		return null
	}

	return (
		<div id="page-content-header" className="page-content-header xl:px-0 py-2 px-4">
			<div className="container mx-auto pt-2">
				{Boolean(breadcrumbs) && (
					<div className="mb-6">
						<Breadcrumbs {...breadcrumbs} showBackButtonOnMobile={false} />
					</div>
				)}

				<div className="md:grid gap-6 grid-cols-3">
					<div
						className={classNames({
							"col-span-2": showAddressSelector,
							"col-span-1": !showAddressSelector && sideContent,
							"col-span-3": !showAddressSelector && !sideContent,
						})}
					>
						{title && (
							<>
								<Text variant="heading-03" color="primary" className={classNames("leading-7", { "mb-1": subTitle })}>
									{title}
								</Text>
								<Text variant="body-short" color="primary">
									{subTitle}
								</Text>
							</>
						)}
					</div>
					{showAddressSelector && (
						<div className="mt-4 md:mt-0">
							<AddressBar />
						</div>
					)}
					{Boolean(sideContent) && <div className="self-center mt-4 md:mt-0 col-span-2">{sideContent}</div>}
				</div>
			</div>
		</div>
	)
}

export default PageHeader
