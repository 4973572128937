import { getContractCustomerID } from "../utils"

/**
 * Call utag view event
 * @param data
 * @constructor
 */
export const CustomUtagView = (data) => {
	if (typeof window.utag !== "undefined" && typeof window.utag.view === "function") {
		window.utag.view(data)
	}
}

/**
 * Call utag link event
 * @param data
 * @constructor
 */
export const CustomUtagLink = (data) => {
	if (typeof window.utag !== "undefined" && typeof window.utag.link === "function") {
		window.utag.link(data)
	}
}

/**
 * Set GA utag_data object
 * @param data object with neew data to set
 * @param merge whether or not to merge current data with new data
 */
export const SetUtagData = (data, merge = true) => {
	if (typeof window.utag !== "undefined") {
		let utagData = data
		if (merge) {
			utagData = {
				...window.utag_data,
				...data,
			}
		}
		window.utag_data = utagData
	}
}

/**
 * Change current view when url changes
 */
export const OnPageChange = (customerID) => {
	const { pathname, href } = document.location

	CustomUtagView({
		customer_id: customerID,
		site_section: customerID ? "Customer My Account" : "Request Service",
		virtual_page_path: pathname,
		virtual_page_url: href,
	})
}
