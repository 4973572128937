import { useSelector } from "react-redux"
import { createSelector } from "reselect"

export const selectUserAlerts = (state) => state.zesty.userAlerts
export const selectHasUserAlertOnLogin = createSelector(
	(state) => state.zesty.userAlerts,
	(userAlerts) => userAlerts.some((userAlert) => userAlert.showOnLogin)
)
export const selectHeaderContent = (state) => state.zesty.header?.content
export const selectFooterContent = (state) => state.zesty.footer?.content
export const selectWhatsCoveredPopupConfigs = (state) => state.zesty.whatsCoveredPopupConfigs
export const selectLimitationsAndExclusions = (state) => state.zesty.limitationsAndExclusions

export const useUserAlerts = () => useSelector(selectUserAlerts)
export const useHasUserAlertOnLogin = () => useSelector(selectHasUserAlertOnLogin)
export const useHeaderContent = () => useSelector(selectHeaderContent)
export const useFooterContent = () => useSelector(selectFooterContent)
export const useWhatsCoveredPopupConfigs = () => useSelector(selectWhatsCoveredPopupConfigs)
export const useLimitationsAndExclusions = () => useSelector(selectLimitationsAndExclusions)

export const useContent = (contentKey) => {
	const contentItem = useSelector((state) => state.zesty[contentKey])
	return [contentItem?.isLoading, contentItem?.content]
}
